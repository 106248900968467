import { useState, useEffect, useRef, useMemo } from "react";
import "../App.css";
export default function FeeBreakDown() {
  const sectionId = "freeBreakDown";
  const sectionRef = useRef(null);
  const isInViewport1 = useIsInViewport(sectionRef);
  if (isInViewport1) {
    document.getElementById(sectionId).className =
      "section feeBreakDown animate__animated animate__fadeInUp";
  }
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );
    useEffect(() => {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
    return isIntersecting;
  }

  return (
    <>
      <div id={sectionId} className="section feeBreakDown" ref={sectionRef}>
        <div className="row row90">
          <div
            className="col-sm-12"
            style={{
              textAlign: "center",
            }}
          >
            <div className="whiteHeading">Transaction Fee Breakdown</div>
          </div>
        </div>
        <div
          className="showOnDesktop"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            className="row row90 dBoxesContainer justify-content-between"
            style={{
              marginTop: 100,
            }}
          >
            <div className="col-sm-3 dTax dBoxes">Development Tax</div>

            <div className="col-sm-3">
              <div className="text">
                Emorya burns 3% from each transaction effectively decreasing the overall supply of the token in the free market.
              </div>
            </div>

            <div className="col-sm-3 dBuyBack dBoxes">Buy Back</div>
          </div>

          <div className="dLine"></div>
          <div className="row row90 dBoxeslower dBoxesContainer justify-content-between ">
            <div className="col-sm-3">
              <div className="text">
              Ecosystem must rise dApps development and security. A special focus on marketing to promote the ecosystem increase adoption.
              </div>
            </div>

            <div className="col-sm-3 dBurnTax dBoxes">Burn Tax</div>

            <div className="col-sm-3">
              <div className="text">
                The 3% of tokens allocated to the locking pool supply could
                involve locking a specific portion of the token supply for a
                predetermined period.
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
